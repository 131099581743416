<template>
  <div class="card-body">
    <h5>
      <span>{{ $t("product-page.flight-only") }}</span>
    </h5>
    <div class="d-flex justify-content-between">
      <div class="p-2" :style="lang === 'he'? 'direction:rtl;':'direction:ltr;'">
        <span v-if="Number(occupancy.adult) === 1">{{ occupancy.adult }} {{ $t("product-page.adult") }}</span>
        <span v-else>{{ occupancy.adult }} {{ $t("product-page.adults") }}</span>
        <span v-if="occupancy.child && Number(occupancy.child) === 1">, {{ occupancy.child }} {{ $t("product-page.child") }}</span>
        <span v-if="occupancy.child && Number(occupancy.child) > 1">, {{ occupancy.child }} {{ $t("product-page.children") }}</span>

        <!-- <p v-if="!occupancy.infant">{{ $t("product-page.no-babies") }}</p> -->
        <p v-if="occupancy.infant && Number(occupancy.infant) === 1">{{ occupancy.infant }} {{ $t("product-page.infant") }}</p>
        <p v-if="occupancy.infant && Number(occupancy.infant) > 1">{{ occupancy.infant }} {{ $t("product-page.infants") }}</p>
      </div>
      <div class="p-2">
        <p class="m-0"><img :src="`${imageDomain}/assets/img/icon_x.png`" alt="icon-x" width="15" height="15"/></p>
        <p v-if="occupancy.infant > 0"><img :src="`${imageDomain}/assets/img/icon_x.png`" alt="icon-x" width="15" height="15"/></p>
      </div>
      <div class="p-2">
        <p class="m-0">{{ getPriceWithSymbol( occupancy.cc, occupancy.price) }}</p>
        <p v-if="occupancy.infant > 0">{{ getPriceWithSymbol( occupancy.cc, occupancy.priceInf) }}</p>
      </div>
      <div class="p-2">
        <p class="m-0"><img :src="`${imageDomain}/assets/img/Equal.png`" alt="icon-equal" width="15" height="15"/></p>
        <p class="m-0" v-if="occupancy.infant > 0"><img :src="`${imageDomain}/assets/img/Equal.png`" alt="icon-equal" width="15" height="15"/></p>
        <!-- <p v-if="occupancy.infant && Number(occupancy.oldTotal) > Number(occupancy.totalPrice)">
          <s v-if="!isOdysseySite && !bypassPaymentState">{{ getPriceWithSymbol( occupancy.cc, occupancy.oldTotal) }}</s>
        </p> -->
      </div>
      <div class="p-2">
        <p class="m-0" v-if="occupancy.infant > 0">{{ getPriceWithSymbol( occupancy.cc, occupancy.totalPrice - occupancy.priceInf * occupancy.infant) }}</p>
        <p class="m-0" v-if="occupancy.infant > 0">{{ getPriceWithSymbol( occupancy.cc, occupancy.priceInf * occupancy.infant) }}</p>
        <p class="m-0"> {{ getPriceWithSymbol( occupancy.cc, occupancy.totalPrice) }}</p>
        <!-- <p v-if="!occupancy.infant && Number(occupancy.oldTotal) > Number(occupancy.totalPrice)">
          <s v-if="!isOdysseySite && !bypassPaymentState">{{ getPriceWithSymbol( occupancy.cc, occupancy.oldTotal) }}</s>
        </p> -->
      </div>
    </div>
    <div class="footer">
      <div class="d-flex justify-content-between">
        <div class="p-2">
          {{ $t('product-page.special-price') }}
        </div>
        <div class="p-2">{{ getPriceWithSymbol( occupancy.cc, occupancy.totalPrice) }}</div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import gMixin from '@/utils/mixins';
import imageUrlMixin from '@/utils/imageUrlMixin';

export default {
  mixins: [gMixin, imageUrlMixin],
  props: {
    occupancy: {
      type: Object,
      default: null,
    },
  },
  computed: {
    ...mapGetters({
      lang: 'GET_LANGUAGE',
      isOdysseySite: 'GET_ODYSSEY_AGENT_STATE',
      bypassPaymentState: 'GET_BYPASS_PAYMENT_STATE',
    }),
  },
  methods: {
    remove() {
      this.$emit('closed', this.index);
    },
  },
};
</script>
<style scoped>
  s {
    opacity: 0.5;
  }
  .card-body {
    margin: auto;
    width: 50%;
  }
  .footer {
    padding: 2px 40px;
    box-sizing: border-box;
    background-color: #e6e7e8;
    background: #e6e7e8;
    background: -webkit-linear-gradient(
      left,
      #1884c7,
      #d0e2ec,
      #c6ddea,
      #a5cce3,
      #1884c7
    );
    background: -o-linear-gradient(
      left,
      #1884c7,
      #d0e2ec,
      #c6ddea,
      #a5cce3,
      #1884c7
    );
    background: -moz-linear-gradient(
      left,
      #1884c7,
      #d0e2ec,
      #c6ddea,
      #a5cce3,
      #1884c7
    );
    background: linear-gradient(
      to left,
      #1884c7,
      #d0e2ec,
      #c6ddea,
      #a5cce3,
      #1884c7
    );
    border: 1px solid #d7d8d9;
    border-radius: 0 0 4px 4px;
    -moz-border-radius: 0 0 4px 4px;
    -webkit-border-radius: 0 0 4px 4px;
    box-shadow: 0 3px 5px 1px #d7d8d9;
    -webkit-box-shadow: 0 3px 5px 1px #d7d8d9;
    -moz-box-shadow: 0 3px 5px 1px #d7d8d9;
    -o-box-shadow: 0 3px 5px 1px #d7d8d9;
    font-size: 16px;
    font-weight: 700;
    width: 100%;
    bottom: 0;
  }

</style>
